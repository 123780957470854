/*

=========================================================
* Swipe - Mobile App One Page Bootstrap 5 Template
=========================================================

* Product Page: https://themesberg.com/product/bootstrap/swipe-free-mobile-app-one-page-bootstrap-5-template
* Copyright 2020 Themesberg (https://www.themesberg.com)

* Coded by https://themesberg.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software. Contact us if you want to remove it.

*/

@import url("https://fonts.googleapis.com/css2?family=Spartan:wght@200;300;400;500;700&display=swap");

// Bootstrap mixins and functions
@import "../../node_modules/bootstrap/scss/mixins";
@import "../../node_modules/bootstrap/scss/functions";
// Change variables here
@import "swipe/variables";

// Bootstrap
@import "../../node_modules/bootstrap/scss/bootstrap";

// Vendor
@import "swipe/vendor";

// Pixel mixins & functions
@import "swipe/mixins";
@import "swipe/functions";

// Utilities
@import "swipe/reboot";
@import "swipe/utilities";

// Layout
@import "swipe/layout";

// Components
@import "swipe/components";

.round-icon {
  border-radius: 25%;
}

.hoverable {
  &:hover {
    cursor: pointer;
  }
}

.cardtextdark {
  background-color: rgba(255, 255, 255, 0.05);
  &:hover {
    background-color: rgba(255, 255, 255, 0.08);
  }
}

.dark_input::placeholder {
  color: rgba(255, 255, 255, 0.9);
  opacity: 1;
}

.fullHeight {
  height: 100vh;
}
@supports (-webkit-touch-callout: none) {
  .fullHeight {
    height: -webkit-fill-available;
  }
}

  p, h1{
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  }


/* Grow */
.hvr-grow {
  vertical-align: middle;
  transform: translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  transition-duration: 0.3s;
  transition-property: transform;
}

.hvr-grow:hover,
.hvr-grow:focus,
.hvr-grow:active {
  transform: scale(1.05);
}

textarea.storypost_textarea{
  border: none;
  background: none;
  outline: none;
  ::placeholder{
    color: "rgba(255,255,255,0.4)"
  }
  &:active, &:focus, &:focus-visible{
    border: none;
    outline: none;
  }
}


.textclamped{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
          line-clamp: 2; 
  -webkit-box-orient: vertical;
}


.headroom--unpinned{
  .header-global{
  background-color: rgba(255,255,255,1);
  a{
    color:"#fff"
  }
 
}}
.headroom--pinned{
  .header-global{
  background-color: rgba(0,0,0,0);
}}

